import React from "react"
import MainLayout from "../components/MainLayout"
import styled from "styled-components"
import SEO from "../components/SEO"
import max from "../data/images/maximilian_rom.jpg"
import placeholder from "../data/images/placeholder_blue.png"

const Container = styled.div`
  background-color: white;
  padding: 0 4rem;
  @media (max-width: 900px) {
    padding: 2rem 1rem;
  }
  @media (max-width: 769px) {
    flex-direction: column;
    text-align: center;
  }
`

const Image = styled.img`
  max-height: 20rem;
  padding: 0 0 2rem 0;
`

const ColumnContainer = styled.div`
  display: flex;
  @media (max-width: 769px) {
    flex-direction: column;
  }
`

const LeftColumn = styled.div`
  flex: 1;
`

const RightColumn = styled.div`
  flex: 1;
`

const Person = styled.div`
  padding-bottom: 2rem;
`

export default ({ location: { pathname } }) => (
  <MainLayout>
    <SEO title="Das Team" pathname={pathname} />
    <Container>
      <h1>Team</h1>
      <Person>
        <Image src={max} alt="Dr. Maximilian Rom" />
        <h2>Dr. Maximilian Rom, Urologe</h2>
        <p>
          Geboren und aufgewachsen in Kärnten absolvierte ich von 2001 bis 2007
          das Studium der Humanmedizin an der Medizinischen Universität Wien.
          Nach Tätigkeiten als Assistenzarzt in der Facharztpraxis für Innere
          Medizin, Dr. Günter Sokol, in 1120 Wien und an der Abteilung für
          Allgemein-, Viszeral-, Thorax- und Gefäßchirurgie am Klinikum
          Deggendorf (Deutschland) absolvierte ich die Ausbildung zum Facharzt
          für Urologie an der Universitätsklinik für Urologie der Medizinischen
          Universität Wien (Facharztprüfung 2013).
        </p>
        <p>
          Als Facharzt für Urologie war ich sowohl im stationären – seit 2015
          als Oberarzt der urologischen Abteilung im Krankenhaus Hietzing – als
          auch im niedergelassenen Bereich als Vertretungs- und Wahlarzt tätig.
        </p>
        <p>
          Seit 1. Oktober 2019 betreibe ich die Facharztordination am Standort
          Breitenfurter Straße 376/1/E/3 als Vertragsarzt aller Kassen.
        </p>
        <br />
        <ColumnContainer>
          <LeftColumn>
            <h3>Mitgliedschaften</h3>
            <p>International Continence Society (ICS)</p>
            <p> European Association of Urology (EAU) </p>
            <p>
              Österreichische Gesellschaft für Urologie (ÖGU)
              <br />
              &emsp;– Arbeitskreis Blasenentleerungsstörung Medizinische
            </p>
            <p>Kontinenzgesellschaft Österreich (MKÖ) </p>
            <p>ISUG Faculty Member (Intensivseminar Urogynäkologie)</p>
          </LeftColumn>
          <RightColumn>
            <h3>Diplome u. Zertifikate</h3>
            <p>Fortbildungsdiplom der Österreichischen Ärztekammer</p>
            <p>ÖÄK-Notarztdiplom</p>
            <p>ÖÄK-Diplom Substitutionsbehandlung</p>
            <p>ÖÄK-Zertifikat Ärztliche Wundbehandlung</p>
          </RightColumn>
        </ColumnContainer>
      </Person>
      {/*<Person>*/}
      {/*  <Image src={placeholder} alt="Frau Charlotte Jakabfi" />*/}
      {/*  <h2>Frau Charlotte Jakabfi, Ordinationsassistentin</h2>*/}
      {/*  /!*<p>*!/*/}
      {/*  /!*  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam*!/*/}
      {/*  /!*  nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,*!/*/}
      {/*  /!*  sed diam voluptua. At vero eos et accusam et justo duo dolores et ea*!/*/}
      {/*  /!*  rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem*!/*/}
      {/*  /!*  ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur*!/*/}
      {/*  /!*  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et*!/*/}
      {/*  /!*  dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam*!/*/}
      {/*  /!*  et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea*!/*/}
      {/*  /!*  takimata sanctus est Lorem ipsum dolor sit amet.*!/*/}
      {/*  /!*</p>*!/*/}
      {/*</Person>*/}
      {/*<Person>*/}
      {/*  <Image src={placeholder} alt="Frau Marta Lipski" />*/}
      {/*  <h2>Frau Marta Lipski, Ordinationsassistentin</h2>*/}
      {/*  /!*<p>*!/*/}
      {/*  /!*  Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam*!/*/}
      {/*  /!*  nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat,*!/*/}
      {/*  /!*  sed diam voluptua. At vero eos et accusam et justo duo dolores et ea*!/*/}
      {/*  /!*  rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem*!/*/}
      {/*  /!*  ipsum dolor sit amet. Lorem ipsum dolor sit amet, consetetur*!/*/}
      {/*  /!*  sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et*!/*/}
      {/*  /!*  dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam*!/*/}
      {/*  /!*  et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea*!/*/}
      {/*  /!*  takimata sanctus est Lorem ipsum dolor sit amet.*!/*/}
      {/*  /!*</p>*!/*/}
      {/*</Person>*/}
    </Container>
  </MainLayout>
)
