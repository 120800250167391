import React, { Component } from "react"
import styled from "styled-components"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons"
import { colors } from "../styles/vars"

const ButtonWrapper = props => {
  const paddingRight = 0.5

  const ButtonContainer = styled.div`
    font-family: inherit;
    font-size: inherit;
    border-radius: 4px;
    border: 1px solid ${props.borderColor ? props.borderColor : "white"};
    background-color: white;
    &:hover {
      background-color: ${colors.LIGHTBLUE};
    }
    cursor: pointer;
    display: flex;
    overflow: hidden;
    margin: ${props.margin};
  `

  const IconContainer = styled.div`
    background-color: ${colors.BLUE};
    color: white;
    padding: 0.2rem
      ${props.subtractPadding ? paddingRight + props.subtractPadding : "0.5"}rem
      0.2rem 0.5rem;
  `

  const TextContainer = styled.div`
    padding: 0.2rem 0.5rem 0.2rem
      ${props.subtractPadding ? paddingRight - props.subtractPadding : "0.5"}rem;
    color: ${colors.BLUE};
  `
  return (
    <ButtonContainer
      onMouseOver={props.handleMouseOver}
      onMouseLeave={props.handleMouseOut}
    >
      <IconContainer>
        <FontAwesomeIcon icon={faCalendarCheck} />
      </IconContainer>
      <TextContainer>{props.children}</TextContainer>
    </ButtonContainer>
  )
}

class Button extends Component {
  state = {
    date: new Date(),
    subtractPadding: 0,
  }

  handleMouseOver = () => {}

  handleMouseOut = () => {}

  render() {
    const { children } = this.props
    const LinkStyling = { textDecoration: "none" }
    return (
      <a href="https://home.cgm-life.de/portal/#/home/?institution=a4ea33d8-96de-4621-8de1-c64c11e2cf83" style={LinkStyling} target="_blank">
        <ButtonWrapper
          handleMouseOver={this.handleMouseOver}
          handleMouseOut={this.handleMouseOut}
          subtractPadding={this.state.subtractPadding}
          margin={this.props.margin}
          borderColor={this.props.borderColor}
        >
          {children}
        </ButtonWrapper>
      </a>
    )
  }
}

export default Button
