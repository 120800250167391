import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from '../styles/vars'

const Container = styled.div`
  display: flex;
  justify-content: flex-end;
  font-size: .8rem;
  font-weight: normal;
  text-transform: uppercase;
  flex: 1;
  @media (max-width: 900px) {
    display:none;
  }
  @media (min-width: 1400px) {
    font-size: 1rem;
  }
  @media (min-width: 1650px) {
    font-size: 1.2rem;
  }
`

const LinkItem = styled.div`
  margin: 2rem 0 -2px;
  @media (min-width: 1400px) {
    margin: 2.4rem 0 -2px;
  }
  @media (min-width: 1650px) {
    margin: 2.5rem 0 -2px;
  }
`

const activeStyleLink = {
  borderBottom: `2px solid ${colors.BLUE}`,
  color: colors.BLUE,
  marginLeft: "1.5rem",
  textDecoration: "none",
}

const styleLink = {
  display: 'flex',
  letterSpacing: "1.5px",
}

const Menu = () => (
  <Container>
    <Link className="link-item" to="/" activeStyle={activeStyleLink} style={styleLink}><LinkItem>Startseite</LinkItem></Link>
    <Link className="link-item" to="/team/" activeStyle={activeStyleLink} style={styleLink}><LinkItem>Team</LinkItem></Link>
    <Link className="link-item" to="/contact/" activeStyle={activeStyleLink} style={styleLink}><LinkItem>Ordination / Anfahrt</LinkItem></Link>
  </Container>
)

export default Menu
