import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhone, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import { useStaticQuery, graphql } from "gatsby"
import styled from "styled-components"
import Button from "./Button"
import { colors } from "../styles/vars"

const Container = styled.div`
  background: ${colors.BLUE};
  position: fixed;
  width: 100%;
  height: 2.6rem;
  z-index: 99;
  @media (max-width: 445px) {
    height: 3.2rem;
  }
  @media (min-width: 1400px) {
    height: 3.2rem;
  }
  @media (min-width: 1650px) {
    height: 3.6rem;
  }
`

const ElementContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  padding: 0.3rem 4rem;
  @media (max-width: 900px) {
    padding: 0.3rem 1rem;
    justify-content: center;
  }
  @media (max-width: 769px) {
    padding: 0.5rem 1rem;
  }
  @media (max-width: 445px) {
    padding: 0.1rem 1rem;
  }
  @media (min-width: 1400px) {
    padding: 0.5rem 4.2rem;
  }
  @media (min-width: 1650px) {
    padding: 0.7rem 4.4rem;
  }
  @media (min-width: 1650px) {
    padding: 0.6rem 4.6rem;
  }
`

const ContactContainer = styled.div`
  display: flex;
  flex: 1;
  @media (max-width: 769px) {
    display: flex;
    flex: 1;
    justify-content: space-between;
  }
  @media (max-width: 445px) {
    flex-direction: column;
    text-align: center;
  }
`

const ButtonContainer = styled.div`
  @media (max-width: 769px) {
    display: none;
  }
`

const Spacer = styled.span`
  margin: 0 1rem;
`

const LinkStyling = { textDecoration: "none", color: "white" }

const TopHeader = () => {
  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            telephone
            email
          }
        }
      }
    `
  )
  return (
    <Container>
      <ElementContainer>
        <ContactContainer>
          <span>
            <span>
              <FontAwesomeIcon icon={faPhone} />
            </span>
            <span>
              <a href={`tel:${data.site.siteMetadata.telephone}`} style={LinkStyling}>
                &nbsp;{data.site.siteMetadata.telephone}
              </a>
            </span>
          </span>
          <Spacer />
          <span>
            <span>
              <FontAwesomeIcon icon={faEnvelope} />
            </span>
            <span>
              <a
                href={`mailto:${data.site.siteMetadata.email}`}
                style={LinkStyling}
              >
                &nbsp;{data.site.siteMetadata.email}
              </a>
            </span>
          </span>
        </ContactContainer>
        <ButtonContainer>
          <Button>Terminvereinbarung</Button>
        </ButtonContainer>
      </ElementContainer>
    </Container>
  )
}

export default TopHeader
