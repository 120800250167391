import React, { Component } from "react"
import styled from "styled-components"
import Menu from "./Menu"
import logo from "../data/images/ logo_final.png"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons"
import { colors } from "../styles/vars"

const Container = styled.div`
  background: white;
  display: flex;
  color: black;
  padding: 2.6rem 4rem 0;
  border-bottom: 1px solid ${colors.BLUE};
  @media (max-width: 900px) {
    padding: 2.6rem 1rem 0;
  }
  @media (max-width: 445px) {
    padding: 3.2rem 1rem 0;
  }
  @media (min-width: 1400px) {
    padding: 3.2rem 4.2rem 0;
  }
  @media (min-width: 1650px) {
    padding: 3.6rem 4.4rem 0;
  }
`

const Logo = styled.img`
  max-height: 4rem;
  @media (max-width: 375px) {
    width: 100%
  }
  @media (min-width: 1400px) {
    max-height: 5rem;
  }
  @media (min-width: 1650px) {
    max-height: 5.4rem;
  }
`

const MobileMenuHolder = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  font-size: 2rem;
  padding-left: 1rem;
  align-items: center;
  color: ${colors.BLUE};
  @media (min-width: 901px) {
    display: none;
  }
`

class Header extends Component {
  render() {
    return (
      <Container>
        <div style={{ display: "flex", margin: "0.5rem 0" }}>
          <Logo src={logo} alt="Logo Urologe Dr. Rom"/>
        </div>
        <MobileMenuHolder onClick={this.props.clickHandler}>
          <span style={{ width: "28px" }}>
            <FontAwesomeIcon
              icon={!this.props.showMobileMenu ? faBars : faTimes}
            />
          </span>
        </MobileMenuHolder>
        <Menu />
      </Container>
    )
  }
}

export default Header
