import React from "react"
import { Helmet } from "react-helmet"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"

const SEO = ({ title, description, keywords, pathname }) => (
  <StaticQuery
    query={query}
    render={({
      site: {
        siteMetadata: {
          defaultTitle,
          titleTemplate,
          defaultDescription,
          siteUrl,
          defaultKeywords,
        },
      },
    }) => {
      const seo = {
        title: title || defaultTitle,
        description: description || defaultDescription,
        keywords: keywords || defaultKeywords,
        url: `${siteUrl}${pathname || "/"}`,
      }
      return (
        <>
          <Helmet title={seo.title} titleTemplate={title ? titleTemplate: ''}>
            <html lang="de" />
            <meta name="description" content={seo.description} />
            <meta name="keywords" content={seo.keywords} />
            {seo.url && <meta property="og:url" content={seo.url} />}
            {seo.title && <meta property="og:title" content={`Urologe Dr. Rom · ${seo.title}`} />}
            {seo.description && (
              <meta property="og:description" content={seo.description} />
            )}
            {seo.keywords && (
              <meta property="og:keywords" content={seo.keywords} />
            )}
            <meta name="og:type" content="website" />
            <link rel="canonical" href={`http://www.urologe-rom.at${pathname !== '/' ? pathname : ''}`} />
          </Helmet>
        </>
      )
    }}
  />
)

export default SEO

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        titleTemplate
        defaultDescription: description
        siteUrl: url
        defaultKeywords: keywords
      }
    }
  }
`

SEO.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  pathname: PropTypes.string,
  keywords: PropTypes.string,
}

SEO.defaultProps = {
  title: null,
  description: null,
  pathname: null,
  keywords: null,
}
