import React, { Component } from "react"
import styled from "styled-components"

import TopHeader from "./TopHeader"
import Header from "./Header"
import Footer from "./Footer"
import MenuMobile from "./MenuMobile"
import { colors } from "../styles/vars"
import { faCalendarCheck } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const Container = styled.div`
  margin: 0 auto;
  // background: white;
  // background: #f7f5f4;
  // background: #9FA4AA;
  @media (min-width: 900px) {
    padding: 4rem 0;
  }
`

const Button = styled.div`
  border-top: 1px solid white;
  position: fixed;
  bottom: 0px;
  width: 100%;
  display: flex;
  color: white;
  height: 2.6rem;
  background-color: ${colors.BLUE};
  cursor: pointer;
  @media (min-width: 769px) {
    display: none;
  }
`

const Spacer = styled.span`
  margin: 0 0.5rem;
`

export default class MainLayout extends Component {
  state = {
    showMobileMenu: false,
  }
  clickHandler = () => {
    this.setState({ showMobileMenu: !this.state.showMobileMenu })
  }
  render() {
    return (
      <>
        <TopHeader />
        <Header
          clickHandler={this.clickHandler}
          showMobileMenu={this.state.showMobileMenu}
        />
        {this.state.showMobileMenu && <MenuMobile />}
        <Container>{this.props.children}</Container>
        <a href="https://home.cgm-life.de/portal/#/home/?institution=a4ea33d8-96de-4621-8de1-c64c11e2cf83" target="_blank">
          <Button>
            <div style={{ margin: "auto" }}>
              <span>
                <FontAwesomeIcon icon={faCalendarCheck} />
              </span>
              <Spacer />
              <span>Terminvereinbarung</span>
            </div>
          </Button>
        </a>
        <Footer />
      </>
    )
  }
}
