import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from '../styles/vars'

const Container = styled.div`
  background: ${colors.BLUE}; 
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-size: 0.8rem;
  padding: 0.5rem 4rem;
  @media (max-width: 769px) {
    padding: 0.5rem 1rem;
    margin-bottom: 2.6rem;
  }
  @media (min-width: 1400px) {
    font-size: 1rem;
    padding: 0.7rem 4.2rem;
  }
  @media (min-width: 1650px) {
    font-size: 1.2rem;
    padding: 0.9rem 4.4rem;
  }
`

export default () => (
  <Container>
    <Link to="/imprint/" style={{textDecoration: "none", color: "white", flex: 1}}>Impressum</Link>
    <div style={{flex: 1, textAlign: "end"}}>&copy; 2019 Urologe Dr. Rom. All rights reserved.</div>
  </Container>
)
