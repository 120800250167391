import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import { colors } from "../styles/vars"

const Container = styled.div`
  border-bottom: 1px solid ${colors.GREY};
  display: flex;
  background-color: white;
  width: 100%;
`

const LinkItem = styled.div``

const activeStyleLink = {
  color: "#6980ad",
  textDecoration: "none",
}

const styleLink = {
  letterSpacing: "1.5px",
}

const List = styled.ul`
  list-style: none;
`

const ListItem = styled.li`
  padding: 0.4rem 0;
`

const Menu = () => (
  <Container>
    <List>
      <ListItem>
        <Link
          className="link-item-mobile"
          to="/"
          activeStyle={activeStyleLink}
          style={styleLink}
        >
          <LinkItem>Startseite</LinkItem>
        </Link>
      </ListItem>
      <ListItem>
        <Link
          className="link-item-mobile"
          to="/team/"
          activeStyle={activeStyleLink}
          style={styleLink}
        >
          <LinkItem>Team</LinkItem>
        </Link>
      </ListItem>
      <ListItem>
        <Link
          className="link-item-mobile"
          to="/contact/"
          activeStyle={activeStyleLink}
          style={styleLink}
        >
          <LinkItem>Ordination / Anfahrt</LinkItem>
        </Link>
      </ListItem>
    </List>
  </Container>
)

export default Menu
